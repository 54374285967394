@font-face {
  font-family: "Noto";
  src: url("./fonts/NotoSans-Bold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Noto";
  src: url("./fonts/NotoSans-Light.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "Noto";
  src: url("./fonts/NotoSans-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Noto";
  src: url("./fonts/NotoSans-Medium.ttf");
  font-weight: 500;
}

body {
  margin: 0;
  font-family: "Noto", "open-sans";
  background-color: #fafafa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
